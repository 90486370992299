import React, { Component } from 'react'
import { Container, Col, Row, Card, Nav, Modal } from 'react-bootstrap'
import axios from 'axios'

import style from '../style/Prices.module.css'

export default class Prices extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			data: [
				{
					title1: "Livrator",
					title2: "part-time",
					description: "Perfect pentru un program de lucru de 20-30 ore/saptamana",
					stats: ["Mentenanta gratuita", "Asistenta 24/24", "Accesorii incluse", "100 lei garantie", "350 km limita/saptamana"],
					oldPrice: 299,
					newPrice: 249,
					discount: 50,
					modalContent: 'Pachetul de inchiriere "Livrator part-time" este perfect pentru cei care au un al doilea job in domeniul livrarilor. Distanta de 350 kilometri, limita in care se poate utiliza bicicleta electrica, este suficienta pentru un program de lucru de 20-30 ore pe saptamana.'
				},
				{
					title1: "Livrator",
					title2: "full-time",
					description: "Gandit pentru un program de lucru de 40-50 ore/saptamana",
					stats: ["Mentenanta gratuita", "Asistenta 24/24", "Accesorii incluse", "100 lei garantie", "600 km limita/saptamana"],
					oldPrice: 349,
					newPrice: 299,
					discount: 50,
					modalContent: 'Pachetul de inchiriere "Livrator full-time" este gandit pentru cei care muncesc doar in domeniul livrarilor. Distanta de 600 kilometri, limita in care poti utiliza bicicleta electrica, este suficienta pentru un program de lucru de 40-60 ore pe saptamana.'
				},
				{
					title1: "Livrator",
					title2: "de top",
					description: "Ideal pentru un program de lucru de 60-70 ore/saptamana",
					stats: ["Mentenanta gratuita", "Asistenta 24/24", "Accesorii incluse", "100 lei garantie", "Fara limita de km"],
					oldPrice: 369,
					newPrice: 319,
					discount: 50,
					modalContent: 'Pachetul de inchiriere "Livrator de top" este ideal pentru cei care doresc sa performeze in domeniul livrarilor. Nu exista o limita de kilometri in care se poate utiliza bicicleta electrica.'
				}
			],
			modalShow: false,
			modalIndex: 0
		}
	}

	handleOpenModal = index => {
		this.setState({
			modalShow: true,
			modalIndex: index
		})
	}

	getBooqableInfo = () => {
		const link = `https://${process.env.REACT_APP_BOOQABLE_NAME}.booqable.com/api/1/product_groups?api_key=${process.env.REACT_APP_BOOQABLE_KEY}`

		axios.get(link).then(response => {
			const data = [...this.state.data]

			data.forEach((item, index) => {
				const remoteItem = response.data.product_groups.find(f => f.name.trim().replace(/\s+/g, '').toLowerCase() === (item.title1 + item.title2).trim().replace(/\s+/g, '').toLowerCase())

				if (remoteItem) {
					const fullDesc = remoteItem.description.split('<hr>')
					const desc = fullDesc[0].trim().replace('<p>', '').replace('</p>', '')
					
					let list = fullDesc[1].trim().split('</li>')
					list.pop()
					list[0] = list[0].replace('<ul>', '')
					list = list.map(l => l.replace('<li>', '').replace('<br>', ''))

					data[index]["description"] = desc
					data[index]["stats"] = list
					data[index]["newPrice"] = remoteItem.base_price
					data[index]["oldPrice"] = remoteItem.base_price + item.discount
				}
			})

			data[0]["modalContent"] = this.props.content.partTime
			data[1]["modalContent"] = this.props.content.fullTime
			data[2]["modalContent"] = this.props.content.deTop

			this.setState({
				data
			})
		}).catch(err => {
			console.log("err to get product_groups:", err)
		})
	}

	updatePriceInfo = () => {
		const data = [...this.state.data]

		data[0]["modalContent"] = this.props.content.partTime
		data[1]["modalContent"] = this.props.content.fullTime
		data[2]["modalContent"] = this.props.content.deTop

		this.setState({
			data
		})
	}

	componentDidMount = () => {
		// this.getBooqableInfo()
		this.updatePriceInfo()
	}

	render() {
		return (
			<section ref={this.props.r} id="prices" className={`closeMenuOutside ${style.prices}`}>
				<Container>
					<div className={style.sectionHeader}>
						<h1 className="display-4">Alege pachetul potrivit</h1>
					</div>

					<Row>
						{this.state.data.map((item, index) => (
							<Col key={`price_${index}`} xs="12" md={(index === this.state.data.length - 1) ? 12 : 6} lg="4" className={style.pricesColumn}>
								<Card className={style.pricesCard} text="white" border="dark">
									<i className={style.priceInfoIcon} onClick={() => this.handleOpenModal(index)}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
											<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
										</svg>
									</i>

									<Card.Body className="text-white" as={Nav.Link} href={this.props.booqLink} target="_blank">
										<Card.Title as="h2" className="text-center mt-4 mb-4 h2">{item.title1}<br />{item.title2}</Card.Title>
										<Card.Subtitle as="h5" className={`text-center mt-2 h5 px-3 ${style.pricesCardSubtitle}`}>{item.description}</Card.Subtitle>

										<ul className={style.pricesCardList}>
											{item.stats.map((stat, ind) => (
												<li key={`stat_${ind}`}>
													<i>
														<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
															<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
														</svg>
													</i>
													<span className="h6">{stat}</span>
												</li>
											))}
										</ul>

										<div className={`mb-2 ${style.pricesCardPrice}`}>
											<div>
												<span className="h6">{item.oldPrice}</span>
												<span className="h6">-{item.discount} Lei</span>
											</div>
											<div className="h3">{item.newPrice} Lei / 7 zile</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				</Container>

				<Modal size="lg" centered show={this.state.modalShow} onHide={() => this.setState({ modalShow: false, modalIndex: 0 })}>
					<Modal.Header closeButton>
						<Modal.Title>
							{`${this.state.data[this.state.modalIndex].title1} ${this.state.data[this.state.modalIndex].title2}`}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>
							{this.state.data[this.state.modalIndex].modalContent}
						</p>
					</Modal.Body>
    		</Modal>
			</section>
		)
	}
}
