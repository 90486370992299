import React from 'react'
import {Container,Row,Col} from 'react-bootstrap';
import { motion } from 'framer-motion';
import style from '../style/Why.module.css'

import casca from "../img/casca.png"
import antifurt from "../img/antifurt.png"
import bicicleta from "../img/bicicleta.png"
import castiguri from "../img/castiguri.png"
import efort from "../img/efort.png"
import livrari from "../img/livrari.png"
import preturi from "../img/preturi.png"
import suport from "../img/suport.png"

export default function WhyUs({r}) {

    const anim = {
        init: {
            scale: 1,
            transition:{
                ease: "easeInOut", duration: 0.1
            }
        },
        hover: {
            scale: 1.15,
            transition:{
                ease: "easeInOut", duration: 0.1
            }
        }
    }
    
  return (
    <section ref={r} id="why" className={`closeMenuOutside ${style.whyContainer}`}>
        <Container>
            <Row>
                <Col>
                    <div className={style.sectionHeader}>
                        <h1 className="display-5">De ce Livrator.ro?</h1>
                    </div>
                    <div className={style.sectionContent}>
                        <ul>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={castiguri} alt="castiguri" />
                                </div>
                                <p className={`h4 ${style.text}`}>Castiguri mai mari</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={efort} alt="efort" />
                                </div>
                                <p className={`h4 ${style.text}`}>Efort minim</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={livrari} alt="livrari" />
                                </div>
                                <p className={`h4 ${style.text}`}>Livrari rapide</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={preturi} alt="preturi" />
                                </div>
                                <p className={`h4 ${style.text}`}>Preturi accesibile</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={bicicleta} alt="bicicleta" />
                                </div>
                                <p className={`h4 ${style.text}`}>Bicicleta electrica</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={casca} alt="casca" />
                                </div>
                                <p className={`h4 ${style.text}`}>Casca de protectie</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={antifurt} alt="antifurt" />
                                </div>
                                <p className={`h4 ${style.text}`}>Antifurt din otel</p>
                            </motion.li>
                            <motion.li variants={anim} initial="init" whileHover="hover">
                                <div className={style.icon}>
                                    <img src={suport} alt="suport" />
                                </div>
                                <p className={`h4 ${style.text}`}>Suport telefon</p>
                            </motion.li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}