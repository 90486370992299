import React, { useEffect } from 'react'
import { useInView } from "react-intersection-observer";
import {Container,Row,Col, Button} from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';

import style from '../style/Smart.module.css'
import bike from '../img/bike.png'

export default function SmartDelivery({r, onScrollTo}) {
    
    const anim = {
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 }},
        hidden: { opacity: 0, y: 80 }
    };

    const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

    return (
        <section ref={r} className={`closeMenuOutside ${style.smartContainer}`}>
            <Container>
                <Row>
                    <Col>
                        <motion.div ref={ref}
                                    animate={controls}
                                    initial="hidden"
                                    variants={anim}>
                            <div className={style.sectionHeader}>
                                <h1 className="display-5">Livreaza inteligent</h1>
                            </div>
                            <div className={style.sectionContent}>
                                <p className="h6">Descopera usurinta de a livra cu o bicicleta electrica si bucuta-te de castiguri mai mari!</p>
                                <Button className={`h6 ${style.deliveryButton}`} onClick={() => onScrollTo(4)}>VEZI PACHETELE</Button>
                            </div>
                        </motion.div>
                        <img src={bike} alt="bike" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}