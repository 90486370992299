import React, { Component, useEffect } from 'react'
import { useInView } from "react-intersection-observer";
import { Container, Col, Row, Image, Table, Button } from 'react-bootstrap'
import { motion, useAnimation } from 'framer-motion';

import image1 from '../img/bike2.png'

import style from '../style/Bikes.module.css'

function TableAnim() {
	const anim = {
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
		hidden: { opacity: 0, y: 50 }
	};
	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	return(
		<motion.div ref={ref}
					animate={controls}
					initial="hidden"
					variants={anim}>
			<Table className={style.bikesTable}>
				<tbody>
					<tr>
						<td className="h5">Autonomie</td>
						<td className="h5">pana la 100km</td>
					</tr>
					<tr>
						<td className="h5">Viteze</td>
						<td className="h5">10 viteze</td>
					</tr>
					<tr>
						<td className="h5">Tip de incarcare</td>
						<td className="h5">3h (0 - 100%)</td>
					</tr>
					<tr>
						<td className="h5">Baterie detasabila</td>
						<td className="h5">da</td>
					</tr>
					<tr>
						<td className="h5">Viteza asistata</td>
						<td className="h5">25 km/h</td>
					</tr>
					<tr>
						<td className="h5">Lumini</td>
						<td className="h5">integrate</td>
					</tr>
					<tr>
						<td className="h5">Aparatoare</td>
						<td className="h5">fata si spate</td>
					</tr>
					<tr>
						<td className="h5">Portbagaj</td>
						<td className="h5">inclus</td>
					</tr>
					<tr>
						<td className="h5">Greutate suportata</td>
						<td className="h5">100 kg</td>
					</tr>
				</tbody>
			</Table>
		</motion.div>
	)
}

export default class Bikes extends Component {
	render() {
		return (
			<section ref={this.props.r} id="bikes" className={`closeMenuOutside ${style.bikes}`}>
				<Container>
					<Row>
						<Col xs="12" md="6">
							<div className={style.sectionHeader}>
								<h1 className="display-5">Econic One Urban</h1>
							</div>
						</Col>
						<Col xs="12" md="6"></Col>
					</Row>

					<Row>
						<Col xs="12" md="6" className={style.bikeContainer}>
							<Image src={image1} alt="Econic One Urban" className="mt-3" />

							<Button className={style.bikesButton} variant="dark" href={this.props.booqLink} target="_blank">Rezerva acum</Button>
						</Col>
						
						<Col xs="12" md="6">
							<TableAnim/>
						</Col>
						
					</Row>
				</Container>
			</section>
		)
	}
}
