import React, { useEffect } from 'react'
import { useInView } from "react-intersection-observer";
import {Container,Row,Col} from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';

import style from '../style/HowToRent.module.css'

export default function HowToRent({r}) {

    const item = {
        hidden: { opacity: 0, x: -80 },
        visible: i => ({ opacity: 1, x: 0, transition: { delay: 0.25*i, duration: 0.5}})
    };

    const controls = useAnimation();
	const [ref, inView] = useInView({rootMargin: '400px'});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);
    
  return (
    <section ref={r} className={`closeMenuOutside ${style.rentContainer}`}>
        <Container>
            <Row>
                <Col>
                    <div className={style.sectionHeader}>
                        <h1 className="display-5">Cum inchiriezi?</h1>
                    </div>
                    <div className={style.sectionContent}>
                        <Row className={style.stepsContainer}>
                            <Col xs="12" md="3" lg="3" className="px-4">
                                <motion.div variants={item} initial="hidden" custom={1} ref={ref}
                                    animate={controls}>
                                    <p className={`h3 ${style.step}`}>1</p>
                                    <p className={`h3 ${style.stepTitle}`}>Alegi un pachet</p>
                                    <p className={`h6 ${style.stepContent}`}>Alege pachetul de inchiriere potrivit nevoilor tale.</p>
                                </motion.div>
                            </Col>
                            <Col xs="12" md="3" lg="3"className="px-4">
                                <motion.div variants={item} initial="hidden" custom={2} ref={ref}
                                    animate={controls}>
                                    <p className={`h3 ${style.step}`}>2</p>
                                    <p className={`h3 ${style.stepTitle}`}>Rezervi bicicleta</p>
                                    <p className={`h6 ${style.stepContent}`}>Completeaza formularul de rezervare cu datele tale.</p>
                                </motion.div>
                            </Col>
                            <Col xs="12" md="3" lg="3"className="px-4">
                                <motion.div variants={item} initial="hidden" custom={3} ref={ref}
                                    animate={controls}>
                                    <p className={`h3 ${style.step}`}>3</p>
                                    <p className={`h3 ${style.stepTitle}`}>Trimiti actele</p>
                                    <p className={`h6 ${style.stepContent}`}>Trimite pe email actele necesare pentru inchiriere.</p>
                                </motion.div>
                            </Col>
                            <Col xs="12" md="3" lg="3"className="px-4">
                                <motion.div variants={item} initial="hidden" custom={4} ref={ref}
                                    animate={controls}>
                                    <p className={`h3 ${style.step}`}>4</p>
                                    <p className={`h3 ${style.stepTitle}`}>Incepi treaba</p>
                                    <p className={`h6 ${style.stepContent}`}>Mergi la depozit, semnezi contractul si ridici bicicleta.</p>
                                </motion.div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}