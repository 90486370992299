import React from 'react'
import {Container,Row,Col, Accordion} from 'react-bootstrap';
import style from '../style/Faq.module.css'

export default function Faq({r, content}) {
  return (
    <section ref={r} id="faq" className={`closeMenuOutside ${style.faqContainer}`}>
        <Container>
            <Row>
                <Col>
                    <div className={style.sectionHeader}>
                        <h1 className="display-5 mb-3">Intrebari frecvente</h1>
                        <p className="h6">Aici gasesti raspunsuri la intrebarile tale</p>
                    </div>
                    <div className={style.sectionContent}>
                        <Accordion className={style.questionContainer} flush>
                            {content.map((item, index) => (
                                <Accordion.Item key={`faq_${index}`} eventKey={index} >
                                    <Accordion.Header className="h5">{item.question}</Accordion.Header>

                                    <Accordion.Body className={`h6 ${style.questionBody}`}>
                                        {(item.answer && item.answer.length > 0) && (
                                            <>
                                                {item.answer.map((ans, indexAns) => {
                                                    if (ans.type === "text") {
                                                        return (
                                                            <div key={`ans_${indexAns}`}>{ans.body}</div>
                                                        )
                                                    } else if (ans.type === "list") {
                                                        return (
                                                            <div key={`ans_${indexAns}`}>
                                                                <ul>
                                                                    {ans.body.map((listItem, listIndex) => (
                                                                        <li key={`listItem_${listIndex}`}>{listItem}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (<></>)
                                                    }
                                                })}
                                            </>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}