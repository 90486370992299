import React, { Component } from 'react'
import { Navbar, Container, Nav, Button } from 'react-bootstrap'
import logo from '../img/logo.png'

import style from '../style/Menu.module.css'

export default class Menu extends Component {
	render() {
		return (
			<Navbar ref={this.props.r} id="menu" className={style.menuNavbar} variant="dark" expand="lg" fixed="top" expanded={this.props.menuExpanded}>
				<Container>
					<Navbar.Brand as={Nav.Link} className={`text-uppercase ${style.navbarBrand}`} onClick={() => this.props.onScrollTo(1)}><img src={logo} alt="logo"/></Navbar.Brand> 
					<Navbar.Toggle aria-controls={`basic-navbar-nav ${style.navbarToggle}`} onClick={this.props.onMenuShow} />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Nav.Link className={`me-5 text-white text-uppercase ${style.menuItem}`} onClick={() => this.props.onScrollTo(2)}>Biciclete</Nav.Link>
							<Nav.Link className={`me-5 text-white text-uppercase ${style.menuItem}`} onClick={() => this.props.onScrollTo(4)}>Preturi</Nav.Link>
							<Nav.Link className={`me-5 text-white text-uppercase ${style.menuItem}`} onClick={() => this.props.onScrollTo(5)}>Avantaje</Nav.Link>
							<Nav.Link className={`me-5 text-white text-uppercase ${style.menuItem}`} onClick={() => this.props.onScrollTo(7)}>Intrebari</Nav.Link>
							<Nav.Link className={`me-5 text-white text-uppercase ${style.menuItem}`} onClick={() => this.props.onScrollTo(8)}>Contact</Nav.Link>

							<Button className={`text-uppercase ${style.menuButton}`} variant="outline-light" href={this.props.booqLink} target="_blank">Rezerva</Button>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		)
	}
}
