import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../img/LIVRATOR.RO.png';
import facebook from '../img/facebook.png';
import pdfTC from '../assets/pdf/Livrator.ro-TC.pdf';
import pdfPC from '../assets/pdf/Livrator.ro-PC.pdf';
import pdfPCookie from '../assets/pdf/Livrator.ro-PCookie.pdf';

import style from '../style/Footer.module.css'

export default function Footer({r, content}) {
    return (
        <footer ref={r} className={`closeMenuOutside ${style.footerContainer}`}>
            <Container>
                <Row>
                    <Col>
                        <div className={style.footerHeader}>
                            <div className={style.footerLogo}><img src={logo} alt="logo"/></div>
                        </div>
                        <div className={style.footerContent}>
                            <ul className={style.legal}>
                                <li><a href={pdfTC} target="_blank" rel="noreferrer" className="h6">Termeni si conditii</a></li>
                                <li><a href={pdfPC} target="_blank" rel="noreferrer" className="h6">Politica de confidentialitate</a></li>
                                <li><a href={pdfPCookie} target="_blank" rel="noreferrer" className="h6">Politica cookie</a></li>
                                <li><a href="https://anpc.ro/" className="h6">ANPC</a></li>
                            </ul>
                            <ul className={style.social}>
                                <li>
                                    <a href={content.facebookLink}>
                                        <img src={facebook} alt="facebook" />
                                    </a>
                                </li>
                            </ul>
                            <ul className={style.copy}>
                                <li><p className="h6 mb-0">{content.companyName}</p></li>
                                <li><p className="h6 mb-0">CUI: {content.cui}</p></li>
                                <li><p className="h6 mb-0">Reg. Com. {content.regCom}</p></li>
                            </ul>
                            <p className={style.copyright}>Livrator.ro {new Date().getFullYear()}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}