import React from 'react'
import style from '../style/Wip.module.css'

const Wip = () => {
	return (
		<div className={style.wipContainer}>
			<div>
				<h1 className="display-1">Livrator.ro</h1>
				<h6 className={`h6 ${style.wipMessage}`}>Site-ul va fi disponibil in curand. Va multumim pentru rabdare!</h6>
			</div>
		</div>
	)
}

export default Wip
