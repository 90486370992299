import React, { Component } from 'react'
import { Carousel, Button } from 'react-bootstrap'
// import { motion } from 'framer-motion';

// import image1 from './../assets/images/image11.jpg'
// import image2 from './../assets/images/image12.jpg'
// import image3 from './../assets/images/image13.jpg'

import style from '../style/Header.module.css'

// const container = {
// 	hidden:{
// 		opacity: 0,
// 	},
// 	visible: {
// 		opacity: 1,
// 		transition: {
// 			ease: "easeOut",
// 			duration: 1
// 		}
// 	}
// };

export default class Header extends Component {
	render() {
		return (
			<div ref={this.props.r}>
				<Carousel id="header" className={`closeMenuOutside ${style.header}`} controls={false} pause={false} slide={false}>
					<Carousel.Item className={style.headerItem}>
						<div className={`${style.imgContainer} ${style.image1}`}>
							<Carousel.Caption className={style.headerCaption}>
								<h3 className={`display-5 ${style.headerCaptionTitle}`}>{this.props.content.slide1.title1} <br /> {this.props.content.slide1.title2}</h3>
								<Button className={style.headerCaptionButton} onClick={() => this.props.onScrollTo(4)}>Vezi pachetele</Button>
							</Carousel.Caption>
						</div>
						
						<p className={`h5 ${style.headerCaptionInfo}`}>Deschis <span className="display-6">7/7</span> zile</p>
					</Carousel.Item>

					<Carousel.Item className={style.headerItem}>
						<div className={`${style.imgContainer} ${style.image2}`}>
							<Carousel.Caption className={style.headerCaption}>
								<h3 className={`display-5 ${style.headerCaptionTitle}`}>{this.props.content.slide2.title1} <br /> {this.props.content.slide2.title2}</h3>
								<Button className={style.headerCaptionButton} onClick={() => this.props.onScrollTo(4)}>Vezi pachetele</Button>
							</Carousel.Caption>
						</div>
						
						<p className={`h5 ${style.headerCaptionInfo}`}>De la <span className={`display-6 ${style.headerCaptionInfoBig}`}>35 <span className={`h5 ${style.headerCaptionInfoSmall}`}>57</span></span> lei/zi</p>
					</Carousel.Item>

					<Carousel.Item className={style.headerItem}>
						<div className={`${style.imgContainer} ${style.image3}`}>
							<Carousel.Caption className={style.headerCaption}>
								<h3 className={`display-5 ${style.headerCaptionTitle}`}>{this.props.content.slide3.title1} <br /> {this.props.content.slide3.title2}</h3>
								<Button className={style.headerCaptionButton} onClick={() => this.props.onScrollTo(4)}>Vezi pachetele</Button>
							</Carousel.Caption>
						</div>
						
						<p className={`h5 ${style.headerCaptionInfo}`}>Viteza <span className="display-6">25</span> km/h</p>
					</Carousel.Item>
				</Carousel>
			</div>
		)
	}
}
