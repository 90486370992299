import React from 'react'
import { motion} from 'framer-motion';
import style from '../style/Loader.module.css'

export default function Loader({setLoading}) {
    const container = {
        exit:{
            y: "-100%",
            transition: {
                when: "afterChildren",
                delayChildren: 0.5,
                duration: 0.3
            }
        }
    };

    const logo = {
        hidden:{
            x: -150,
            skew: 20,
            opacity: 0
        },
        visible: {
            x: 0,
            skew: 0,
            opacity: 1
        },
        exit: {
            x: 550,
            skew: 20,
            opacity: 0,
            transition: {
                ease: "easeOut",
                duration: 0.2
            }
        }
    };

    return (
        <motion.div className="loaderContainer" variants={container} initial="hidden" animate="visible" exit="exit" onAnimationComplete={() => setLoading(true)}>
            <motion.p variants={logo} className={`display-5 ${style.row1}`}>LIVRATOR.RO</motion.p>
            <motion.p variants={logo} className={`display-5 ${style.row2}`}>BUCURESTI</motion.p>
        </motion.div>
    )
}